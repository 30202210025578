import React from 'react'
import styles from './Sphere.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Sphere/img_1.webp'
import image_2 from '../../image/Sphere/img_2.webp'
import image_3 from '../../image/Sphere/img_7.webp'
import image_4 from '../../image/Sphere/img_4.webp'
import image_5 from '../../image/Sphere/img_5.webp'
import image_6 from '../../image/Sphere/img_6.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Sphere = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='Sphere' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Сферы применения<span> шерсти</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_1} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Безопасный подстилочный материал для животных</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_2} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Упаковка и транспортировка различного оборудования</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_3} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Упаковка и транспортировка хрупких предметов</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_4} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Мульчирования почвы в сельском хозяйстве</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_5} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Производство фибролитовых плит и утеплителя</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
            <li className={styles.cell}>
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_6} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Элемент дизайна и материал для рукоделий</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                </div>
            </li>
        </motion.ul>
        </div>   
    </motion.section>
    )
}