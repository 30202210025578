import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_5 from '../../image/Deadlines/img_5.svg'

export const Scheme = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .9}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Deadlines' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как мы<span> работаем?</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку, для покупки древесной шерсти, на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>После отправки заявки с вами свяжется менеджер с целью уточнить ваш заказ и обговорить все детали по составу древесной шерсти, оплате и доставке.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Счет</h3>
                            <p className={styles.item_text}>После согласования всех ключевых условий и сроков поставки  древесной шерсти, мы выставляем счет для оплаты и подписываем договор(при необходимости).</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Отгрузка</h3>
                            <p className={styles.item_text}>После подтверждения поступления денежных средст мы производим отгрузку. Забрать оплаченную древесную шерсть можно самовывозом или воспользовавшись услугами транспортной компании партнера.</p>
                        </li>
                    </motion.ul>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
            </div>   
        </motion.section>
        
        
    )
}
