import React from 'react'
import { motion } from "framer-motion"
import styles from './Services.module.scss'
import image_1 from '../../image/Services/img_1.webp'
import image_3 from '../../image/Services/img_4.webp'
import image_4 from '../../image/Services/img_6.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

export const Services = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Цены на нашу<span> продукцию</span></motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.cell}>
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_3} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Древесная шерсть 9мм-10мм</h3>
                            <p className={styles.cell_price}>186 руб/кг</p>
                            <p className={styles.cell_text}>Продаём от 1 тюка. Paзмeр тюкa cпреcсoвaнной шерсти 40см/50см/90cм, вес тюкa 8-9кг.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                        </div>
                    </li>    
                    <li className={styles.cell}>
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_1} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Древесная шерсть 3мм-4мм</h3>
                            <p className={styles.cell_price}>186 руб/кг</p>
                            <p className={styles.cell_text}>Продаём от 1 тюка. Paзмeр тюкa cпреcсoвaнной шерсти 40см/50см/90cм, вес тюкa 8-9кг.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                        </div>
                    </li>
                    <li className={styles.cell}>
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_4} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Древесная шерсть 5мм-6мм</h3>
                            <p className={styles.cell_price}>186 руб/кг</p>
                            <p className={styles.cell_text}>Продаём от 1 тюка. Paзмeр тюкa cпреcсoвaнной шерсти 40см/50см/90cм, веc 8-9кг.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>заказать</a>
                        </div>
                    </li>
                    </motion.ul>
                    <motion.p className={styles.text} variants={boxAnimation}>Уважаемые клиенты, для вашего удобства мы предоставляем услуги по упаковке и транспортировке древесной шерсти до терминала транспортной компании. Стоимость упаковки древесной шерсти в полиэтилен составляет <span>150₽ </span>за тюк, стоимость транспортировки до терминала транспортной компании <span>200₽ </span>за тюк.</motion.p>
                </div>
        </motion.section>
        
    )
}